import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Menu from "../components/v2023/Blog/Menu/menu"
import CardPost from "../components/v2023/UI/Card/card-post"
import NewLayout from "../components/new_layout"


const NewBlogCategory = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const page = data.page.edges[0].node
    const categories = data.categories.edges
    const recommended = data.recommended.edges
    const blog = data.blog.frontmatter
    const category = data.category
    const posts = data.posts.edges

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={category.seo.title}
                description={category.seo.meta_description}
                translates={pageContext.translates}
                image={category.seo?.image_2?.localFile?.publicURL}

            />

            <main className="main">
                <Menu categories={categories} blog={blog} lang={lang} />

                <div className="main__section main__section--50 main__section--50--lg white-section green-sm">
                    <div className="container">
                        <div className="container__50">
                            <h1 className="merriweather">{category.name}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={category.intro} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <ReactMarkdown children={category.description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="grid-lg-3">
                            {posts.map((post, index) => {
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.name}
                                    image={post.node.photo_2}
                                    alternativeText={post.node.photo_2.alternativeText}
                                    url={`${baseUrl}/blog/${post.node.url}`}
                                    tag={post.node.blog_categories[0].name}
                                    tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100" data-aos="fade-up">
                    <div className="container">
                        <h2>{blog.recommended}</h2>
                        <div className="grid-lg-3-h">
                            {recommended.map((post, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <CardPost
                                    key={index}
                                    lang={lang}
                                    name={post.node.name}
                                    image={post.node.photo_2}
                                    url={`${baseUrl}/blog/${post.node.url}`}
                                    date={post.node.creation_datetime}
                                    tag={post.node.blog_categories[0].name}
                                    tagUrl={`${baseUrl}/blog/${post.node.blog_categories[0].url}`}
                                    showMore={true} 
                                    animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </main>
        </NewLayout>
    )
}

export default NewBlogCategory

export const blogCategoryQuery = graphql`
    query ($id: String!, $idSearch: Int!, $langKey: String!) {
        page: allStrapiBlog (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    title
                    search_title
                    search_placeholder
                    search_button
                    search_show_all
                }
            }
        }
        categories: allStrapiBlogCategories (filter: { locale: { eq: $langKey } }) {
            edges {
                node {
                    id
                    name
                    url
                }
            }
        }
        blog: markdownRemark(
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "blog"
                }
            }) {
            frontmatter {
                url
                subscribe
                see_more
                see_less
                view_more
                recommended
                explore
                newsletter {
                    title
                    description
                }
            }
        }
        recommended: allStrapiBlogPosts (filter: { recommended: {eq: true}, locale: {eq: $langKey} }, limit: 3) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 912
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    creation_datetime(formatString: "DD/MM/YYYY", locale: $langKey)
                }
            }
        }
        category: strapiBlogCategories (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    alternativeText
                    localFile {
                        publicURL

                    }
                }
            }
            id
            name
            intro
            description
            blog_posts {
                id
                name
                url
            }
        }
        posts: allStrapiBlogPosts (
            sort: { fields: [creation_datetime], order: [DESC] }
            filter: {
                locale: {eq: $langKey}
                blog_categories: {
                    elemMatch: { id : {eq: $idSearch} }
                }
            }
        ) {
            edges {
                node {
                    id
                    name
                    url
                    blog_categories {
                        id
                        name
                        url
                    }
                    photo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 700
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    content
                }
            }
        }
    }
`
